import React from "react";
import {
  Copyright,
  GeneralChildren,
  IllustrationRight,
  LoginGeneralContainer,
  FormContainer,
  IllustrationRightContainer,
} from "./style";
import Illustration from "../../../assets/images/login-bg.jpg";

export default function LoginGeneral({ children }) {

  return (
    <LoginGeneralContainer>
      { /*<Copyright>© Chat LoadOut • Since 2023<br />Release 20250105A</Copyright>*/ }
      <GeneralChildren>
        <FormContainer>{children}</FormContainer>
      </GeneralChildren>
      <IllustrationRightContainer>
        <IllustrationRight src={Illustration} alt="Ilustração" />
      </IllustrationRightContainer>
    </LoginGeneralContainer>
  );
}
